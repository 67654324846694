import createAction from '@clearscore-group/lib.helpers.create-action';
import createRequestPayload from '@clearscore-group/lib.helpers.create-request-payload';
import getEnv from '@clearscore-group/lib.helpers.envs';

import { getWebviewAppVersion } from './utils';
import {
    MARKET_SET_LOCALES,
    MARKET_SET_ACTIVE_LOCALE,
    MARKET_SET_MARKETS,
    MARKET_SET_ACTIVE_MARKET,
    MARKET_SET_ENVS,
    MARKET_FETCH_CONFIG,
    MARKET_FETCH_CONFIG_PENDING,
    MARKET_FETCH_SDUI_CONFIG,
} from './types';

export const setMarkets = (markets) => ({ type: MARKET_SET_MARKETS, payload: markets });

export const setLocales = (locales) => ({ type: MARKET_SET_LOCALES, payload: locales });

export const setMarketsEnvs = (envs) => ({ type: MARKET_SET_ENVS, payload: envs });

export const setActiveMarket = (market) => createAction(MARKET_SET_ACTIVE_MARKET, { market });

export const setFetchPending = () => ({ type: MARKET_FETCH_CONFIG_PENDING });

export const fetchMarketConfig = (market) => {
    const meta = {
        market,
    };
    const timeout = getEnv('MARKET_REQUEST_TIMEOUT') || 1000;
    const appVersion = getWebviewAppVersion();

    const headers = {
        'x-app-id': getEnv('APP'),
        'x-market-id': market,
        ...(appVersion && { 'x-cs-app-version': appVersion }),
    };

    return createAction(
        MARKET_FETCH_CONFIG,
        createRequestPayload('international', '/render-engine-local/v1/config', 'get', null, meta, headers, timeout),
    );
};

export const fetchSduiMarketConfig = () =>
    createAction(MARKET_FETCH_SDUI_CONFIG, createRequestPayload('international', '/sdc-feature/v1/config', 'get'));

export const setActiveLocale = (activeLocale) => createAction(MARKET_SET_ACTIVE_LOCALE, activeLocale);
