import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';
import resolveRequest, { getRequestStatus } from '@clearscore-group/lib.helpers.resolve-request';

export const getSDUIFetchStatus = createSelector(
    (state) => resolveRequest(state, 'market.sduiConfigFetchStatus'),
    (fetchStatus) => getRequestStatus(fetchStatus),
);

const getSDUIConfig = (state) => resolveProp('market.sduiConfig', state);
export default createSelector(getSDUIConfig, (sduiConfig) => sduiConfig);
