import marketGetPredicate from './getPredicate';
import marketGetFetchStatus from './getFetchStatus';
import marketGetMarkets from './getMarkets';
import marketGetActiveMarket from './getActiveMarket';
import marketGetLocales from './getLocales';
import marketGetActiveLocale from './getActiveLocale';
import marketGetConfig from './getConfig';
import marketGetResolution from './getResolution';
import marketLegacyGetLanguage from './legacy.getLanguage';
import marketGetSDUIConfig, { getSDUIFetchStatus } from './getSDUIConfig';

export const getPredicate = marketGetPredicate;
export const getFetchStatus = marketGetFetchStatus;
export const getConfig = marketGetConfig;
export const getMarkets = marketGetMarkets;
export const getActiveMarket = marketGetActiveMarket;
export const getLocales = marketGetLocales;
export const getActiveLocale = marketGetActiveLocale;
export const getResolution = marketGetResolution;
export const getLegacyLanguage = marketLegacyGetLanguage;
export const getSDUIConfig = marketGetSDUIConfig;
export const getSDUIConfigFetchStatus = getSDUIFetchStatus;
